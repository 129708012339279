import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0OrganizationData, Auth0OrganizationID, Auth0OrganizationName, Auth0UserMembership } from '@gr/shared/models';
import { StrictMode, useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AppMain from './AppMain';
import { AUTH0_MAIN_CONFIG, Auth0ConfigContextProvider } from './contexts/Auth0ConfigContext';
import { IMembershipContext, MembershipContextProvider } from './contexts/MembershipContext';
import { Auth0FrontendConfig, getUrlParam, removeSubdomain } from './providers/auth0-data.provider';
import { auth0Audience, auth0Scope } from './providers/auth0.service';
import './styles.scss';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <Auth0App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
);

function Auth0App(): JSX.Element {
  const BASE_URI = removeSubdomain(window.location.origin);

  const [auth0Config, setAuth0Config] = useState<Auth0FrontendConfig | undefined>(undefined);
  const [loadedConfig, setLoadedConfig] = useState<Auth0FrontendConfig | undefined>(undefined);

  // Auth0 Controlled Params
  const invitation = getUrlParam('invitation') ?? undefined;
  const organizationId = (getUrlParam('organization') ?? auth0Config?.organizationId ?? undefined) as Auth0OrganizationID | undefined;

  // Custom Params
  const organizationName = getUrlParam('org') as Auth0OrganizationName | undefined;

  const [membership, setMembership] = useState<Auth0UserMembership>();
  const [organizations, setOrganizations] = useState<Auth0OrganizationData[]>();
  const [isMembershipLoaded, setMembershipLoaded] = useState(false);

  const membershipContext: IMembershipContext = {
    membership,
    setMembership,

    organizations,
    setOrganizations,

    isMembershipLoaded,
    setMembershipLoaded,
  };

  const setConfig = (config: Auth0FrontendConfig) => {
    setAuth0Config(config);
    setLoadedConfig(config);
  };

  useEffect(() => {
    if (!!auth0Config && !!loadedConfig && auth0Config.organization !== loadedConfig.organization) {
      setLoadedConfig(undefined);
    }
  }, [auth0Config, loadedConfig]);

  return (
    <Auth0ConfigContextProvider value={{ auth0Config, setAuth0Config }}>
      <MembershipContextProvider value={membershipContext}>
        {!loadedConfig && (
          <Auth0Provider
            domain='wondercave.us.auth0.com'
            redirectUri={`${BASE_URI}?main=true`}
            skipRedirectCallback={window.location.href.includes(`?org=${organizationName ?? true}`)}

            clientId={AUTH0_MAIN_CONFIG.clientId}
            invitation={invitation}
            organization={AUTH0_MAIN_CONFIG.organizationId}

            cacheLocation={'localstorage'}
            scope={auth0Scope}
            audience={auth0Audience}
          >
            <AppMain
              targetOrgId={organizationId}
              targetOrgName={organizationName}
              setAuth0Config={setConfig}
            />
          </Auth0Provider>
        )}

        {!!loadedConfig && (
          <Auth0Provider
            domain='wondercave.us.auth0.com'
            redirectUri={`${BASE_URI}?org=${organizationName ?? true}`}
            skipRedirectCallback={window.location.href.includes('?main=true')}

            clientId={loadedConfig.clientId}
            invitation={invitation}
            organization={organizationId}

            cacheLocation={'localstorage'}
            scope={auth0Scope}
            audience={auth0Audience}
          >
            <App auth0Config={loadedConfig} />
          </Auth0Provider>
        )}
      </MembershipContextProvider>
    </Auth0ConfigContextProvider>
  );
}