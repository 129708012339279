import useRoles from '@gr/portal/hooks/useRoles';
import {
  Auth0Role,
  ExternalV1CreateTestNumberRequest,
  FilterDataTypeEnum,
  FilterOperatorEnum,
  IHttpResponse,
  ISearchFilter,
  ISearchRequest,
  ITestNumberDto
} from '@gr/shared/models';
import { IDeleteTestNumberRequest } from '@gr/shared/models/domain/test-numbers/delete-test-number-request.interface';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { axiosPost } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import useTestNumbers from '../../hooks/useTestNumbers';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import Search from '../shared/Table/Search';
import { Table } from '../shared/Table/Table';
import { IColumn } from '../shared/Table/types';
import HealthCheckNumberDetailsPanel from './HealthCheckNumberDetailsPanel';
import { ITestNumberForm, defaultTestNumberTableOptions, getColumns, healthCheckFilterDropdownOptions } from './types';

const HealthCheckNumbers = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedItem, setSelectedItem] = useState<any>();
  const [tableOptions, setTableOptions] = useState(defaultTestNumberTableOptions);
  const { addNotification } = useNotifications();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [{ data: testNumberResponse, loading, error }, refetch] = useTestNumbers(tableOptions);

  const isSuperAdmin = useRoles([Auth0Role.GR_ADMIN]);
  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  const [{ data: createData, loading: createLoading, error: createError }, createTestNumber] = useAxios<
    IHttpResponse<string>
  >(
    {
      url: '/v1/test-numbers',
      method: 'POST',
    },
    { manual: true }
  );

  const testNumbers: ITestNumberDto[] = testNumberResponse?.data?.records ?? [];

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (err) { }
  };

  const columns = getColumns(
    {
      name: (item: ITestNumberDto) => {
        openDetailsPanel(item);
      },
    },
    {
      number: (item: ITestNumberDto) => {
        const numberAsString = item.number.toString();
        return (
          <>{`(${numberAsString.substring(0, 3)}) ${numberAsString.substring(3, 6)}-${numberAsString.substring(6)}`}</>
        );
      },
    }
  );

  const openDetailsPanel = (item?: ITestNumberDto) => {
    if (item) {
      setSelectedItem(item);
    }
    setShowDetailsPanel(true);
  };

  const handleSubmit = async (formData: ITestNumberForm) => {
    const request: ExternalV1CreateTestNumberRequest = {
      name: formData.name,
      number: formData.number!,
      clientIds: formData.clients && formData.clients.length > 0 ? formData.clients?.map((x) => x.value as string)! : undefined,
      hasAllClients: isSuperAdmin ? formData.allClients : undefined // if user is not GR_ADMIN then they will not be allowed to send has all clients. do not include in request if not
    };
    try {
      console.log('creating test numbers');
      const createdTestNumber = await createTestNumber({ data: { ...request } });
      console.log('created test number', createdTestNumber);
      closeDetailsPanel();

      handleRefetch();

      addNotification({ header: 'Health Check Number created successfully' });
    } catch (err) {
      console.log('error occured');
      console.error(err);
    }
  };

  const handleDelete = async () => {
    if (!selectedItem) {
      return;
    }

    const deleteRequest: IDeleteTestNumberRequest = {
      id: selectedItem.id,
      clientId: selectedItem.clientId,
    };

    setDeleteLoading(true);

    try {
      await axiosPost('/v1/test-numbers-delete', deleteRequest);

      closeDetailsPanel();

      handleRefetch();

      addNotification({ header: 'Health Check Number deleted successfully' });
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const closeDetailsPanel = () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const filterColumns: IColumn[] = columns.filter((col) => ['name', 'clientName'].includes(col.fieldName));

  const handleSearch = (overrideText?: string) => {
    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchText,
    };

    if (overrideText !== null && overrideText !== undefined) {
      setSearchText(overrideText);
      searchFilter.value = overrideText;
    }

    const newSearchOptions = { ...defaultTestNumberTableOptions };

    const nonSearchFilters = tableOptions.filters.filter((filter) => filter.fieldName !== 'name');

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];

    setTableOptions(newSearchOptions);
  };

  return (
    <>
      <h2 id="test-number-title" className="pb-2 dark:text-white">
        Health Check Numbers
      </h2>

      <div className="flex justify-between pb-2">
        <Search
          id="hcNameSearch"
          name="hcNameSearch"
          placeholder="Name"
          searchText={searchText}
          setSearchText={setSearchText}
          handleSearch={handleSearch}
          tableOptions={tableOptions}
        />
        <Button
          id="test-number-add"
          className="self-end"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add
        </Button>
      </div>

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={testNumbers}
        count={testNumberResponse?.data?.totalCount}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        filter
        filterColumns={filterColumns}
        filterDropdownOptions={healthCheckFilterDropdownOptions}
        paginate
      />

      <HealthCheckNumberDetailsPanel
        errorMessage={createError?.response?.data?.message}
        show={showDetailsPanel}
        loading={createLoading}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={handleSubmit}
        leftPanelButtonOptions={[
          {
            text: 'Delete',
            visible: !!selectedItem,
            variant: ButtonVariantEnum.DELETE,
            loading: deleteLoading,
            onClick: handleDelete,
          },
        ]}
      />
    </>
  );
};

export default HealthCheckNumbers;
