import { SidebarContext } from '@gr/portal/contexts/SidebarContext';
import { Auth0FrontendConfig } from '@gr/portal/providers/auth0-data.provider';
import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useContext } from 'react';
import { getFestiveStyle } from '../Other/types';
import SidebarContent from './SidebarContent';

interface ILeftNavProps {
  auth0Config: Auth0FrontendConfig;
}

const LeftNav = ({
  auth0Config,
}: ILeftNavProps): JSX.Element => {
  const { devMode, sidebarOpen, setSidebarOpen } = useContext(SidebarContext);

  // TODO: Use css classes for form factor
  const smallFormFactor: boolean = window.innerWidth < 768;

  return (
    <>
      <Transition.Root show={sidebarOpen && smallFormFactor} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex md:hidden"
          onClose={() => {
            setSidebarOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex flex-col flex-1 w-full pb-4 bg-white max-w-tiny dark:bg-slate-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 pt-2 -mr-12">
                  <button
                    type="button"
                    className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              {/* // Collapsed Nav (only seen on small screens) */}
              <div className="flex-1 h-0 mt-5 overflow-y-auto">
                <SidebarContent auth0Config={auth0Config} />
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* // Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {sidebarOpen && !smallFormFactor && (
        <div className="flex flex-shrink-0 overflow-auto bg-white">
          <div className="flex flex-col">
            <SidebarContent auth0Config={auth0Config} />
          </div>
        </div>
      )}

      {(!sidebarOpen || (sidebarOpen && smallFormFactor)) && (
        <div
          className={`sticky top-0 z-10 px-2 pt-5 bg-white dark:bg-slate-800 ${devMode ? getFestiveStyle() : 'bg-gray-100'
            }`}
        >
          <button
            type="button"
            className="inline-flex p-2 rounded-md text-wc-blue hover:bg-gray-100 hover:text-sky-800"
            onClick={() => setSidebarOpen(!sidebarOpen)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
      )}
    </>
  );
};

export { LeftNav };

