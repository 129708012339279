import { Auth0FrontendUser, Auth0OrganizationAppID, Auth0OrganizationID, Auth0OrganizationName } from '@gr/shared/models';
import { useLocation } from 'react-router-dom';

export interface Auth0FrontendConfig {
  label: string;
  clientId: Auth0OrganizationAppID;
  organization: Auth0OrganizationName,
  organizationId: Auth0OrganizationID;
  apiUrl: string;
  websocketUrl: string;
  gsdUrl: string;
  redirectUrl: string;
}

export function getWebSocketUrl(user: Auth0FrontendUser) {
  return getConfigByUser(user)?.websocketUrl;
}

export function getApiUrl(user: Auth0FrontendUser) {
  return getConfigByUser(user)?.apiUrl;
}

export function getOrganizationLabel(user: Auth0FrontendUser) {
  return getConfigByUser(user)?.label;
}

export function getConfigByUser(user: Auth0FrontendUser) {
  return {
    label: user.org_display_name,
    clientId: user.auth_client_id,
    organization: user.organization_name,
    organizationId: user.org_id,

    redirectUrl: user.org_metadata.redirect_url,
    apiUrl: user.org_metadata.api_url,
    websocketUrl: user.org_metadata.websocket_url,
    gsdUrl: user.org_metadata.gsd_url,
  };
}

export function getUrlParam(name: string) {
  const params = new URLSearchParams(useLocation().search);
  return params.has(name) ? params.get(name) : undefined;
}

export function removeSubdomain(url: string) {
  const obj = new URL(url);
  const parts = obj.hostname.split('.');

  if (parts.length > 2) {
    const domain = parts.slice(-2).join('.');

    const subDomain = parts?.[0];
    const isPreProd = subDomain === 'dev' || subDomain === 'staging';

    obj.hostname = isPreProd ? `${subDomain}.${domain}` : domain;
  }

  return obj.href;
}

export function goToOrg(org: Auth0OrganizationName) {
  window.location.replace(`${removeSubdomain(window?.location?.origin)}?org=${org}`);
}