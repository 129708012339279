import { configure } from 'axios-hooks';
import { useEffect, useState } from 'react';
import "react-datetime/css/react-datetime.css";
import { Route, Switch } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import { TokenResponse, configureAxiosWithAuth0 } from './authAxios';
import Login from './components/Login';
import { PageNotFound } from './components/shared/Auth/PageNotFound';
import { useMembership } from './contexts/MembershipContext';
import { initializeWebSocket } from './contexts/SocketContext';
import { useAuth0UserData } from './hooks/useAuth0Custom';
import { Auth0FrontendConfig } from './providers/auth0-data.provider';
import { auth0Audience, auth0Scope } from './providers/auth0.service';

export interface IAppProps {
  auth0Config: Auth0FrontendConfig;
}

const App = ({
  auth0Config,
}: IAppProps): JSX.Element => {
  const {
    isAuthenticated: isLoggedIn,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    logout: auth0Logout,
    user: auth0User,
  } = useAuth0UserData();

  const { setMembership, setMembershipLoaded } = useMembership();

  const [isConfigured, setIsConfigured] = useState(false);

  useEffect(() => {
    if (isConfigured) {
      return;
    }

    if (isLoggedIn) {
      const auth = async () => {
        try {
          await getAccessToken();
        } catch (e) {
          console.error(e);
        }
      };

      if (isLoggedIn) {
        auth();
      }

      initializeWebSocket(auth0User);
    }
  }, [isLoggedIn, auth0Config, isConfigured]);

  useEffect(() => {
    if (isLoggedIn && !!auth0Config && !!auth0User) {
      setMembership({
        organization: auth0Config?.organization,
        roles: [auth0User?.auth_roles?.[0]],
      });

      setMembershipLoaded(true);
    }
  }, [isLoggedIn, auth0Config, auth0User]);

  async function getAccessToken() {
    if (!auth0Config) {
      return;
    }

    const response = await getAccessTokenSilently({
      organization: auth0Config.organizationId,
      detailedResponse: true,
      audience: auth0Audience,
      ignoreCache: true,
      scope: auth0Scope,
    });

    const token: TokenResponse = {
      id_token: response.id_token,
      access_token: response.access_token,
      expires_in: response.expires_in,
      scope: response.scope,
    };

    await configureAxios(token);
  }

  async function authenticateWithConsent() {
    if (!auth0Config) {
      return;
    }

    const authToken = await getAccessTokenWithPopup({
      organization: auth0Config.organizationId,
      audience: auth0Audience,
      ignoreCache: true,
      scope: auth0Scope,
    });

    const response: TokenResponse = {
      access_token: authToken,
      expires_in: 1000000,
    };

    await configureAxios(response);
  }

  async function configureAxios(token: TokenResponse) {
    if (!auth0Config) {
      return;
    }

    const authAxios = configureAxiosWithAuth0({ organization: auth0Config.organization, token, auth0User, auth0Logout });

    configure({
      axios: authAxios,
      defaultOptions: { useCache: false },
    });

    setIsConfigured(true);
  }

  return (
    <Switch>
      <Route path="/app" render={(props) => <AppWrapper configured={isConfigured} auth0Config={auth0Config} />} />
      <Route
        exact
        path="/"
        render={(props) => (
          <Login
            auth0Config={auth0Config}
            isConfigured={isConfigured}
            authenticateWithConsent={authenticateWithConsent}
          />
        )}
      />
      <Route
        exact
        path="/login"
        render={(props) => (
          <Login
            auth0Config={auth0Config}
            isConfigured={isConfigured}
            authenticateWithConsent={authenticateWithConsent}
          />
        )}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
};

export default App;
