import { Auth0OrganizationAppID, Auth0OrganizationID, Auth0OrganizationName } from '@gr/shared/models';
import { LoadingIndicator } from '@Wonder-Cave/ui';
import { useEffect } from 'react';
import { useMembership } from './contexts/MembershipContext';
import { useAuth0UserData } from './hooks/useAuth0Custom';
import { Auth0FrontendConfig } from './providers/auth0-data.provider';

export interface IAppMainProps {
  targetOrgId: Auth0OrganizationID | undefined;
  targetOrgName: Auth0OrganizationName | undefined;
  setAuth0Config: (config: Auth0FrontendConfig) => void;
}

const AppMain = ({
  targetOrgId,
  targetOrgName,
  setAuth0Config,
}: IAppMainProps): JSX.Element => {
  const {
    isLoading: isAuth0Loading,
    isAuthenticated: isLoggedIn,
    user: auth0User,
    loginWithRedirect,
  } = useAuth0UserData();

  const { setOrganizations } = useMembership();

  const getMatchingOrganization = () => {
    const subdomain = window?.location?.host?.split('.')?.[0];
    const orgs = auth0User?.organizations ?? [];

    return orgs.find(org => org.id?.toLowerCase()?.trim() === targetOrgId?.toLowerCase()?.trim())
      ?? orgs.find(org => org.name?.toLowerCase()?.trim() === targetOrgName?.toLowerCase()?.trim())
      ?? orgs.find(org => org.name?.toLowerCase()?.trim() === subdomain?.toLowerCase()?.trim())
      ?? orgs.find(org => org.name?.toLowerCase()?.trim() !== Auth0OrganizationName.P2P?.toLowerCase()?.trim());
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    const match = getMatchingOrganization();

    if (isLoggedIn && match) {
      const config: Auth0FrontendConfig = {
        label: match.display_name,
        organization: match.name as Auth0OrganizationName,
        organizationId: match?.id as Auth0OrganizationID,

        clientId: match.metadata.client_id as Auth0OrganizationAppID,
        redirectUrl: match.metadata.redirect_url,
        apiUrl: match.metadata.api_url,
        websocketUrl: match.metadata.websocket_url,
        gsdUrl: match.metadata.gsd_url,
      };

      setOrganizations(auth0User?.organizations ?? []);

      setAuth0Config(config);
    }
  }, [targetOrgName, isLoggedIn, auth0User]);

  if (!isAuth0Loading && !isLoggedIn) {
    loginWithRedirect();
  }

  return <LoadingIndicator size={16} position="CENTER" vPosition="CENTER" />;
};

export default AppMain;
