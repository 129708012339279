import { Auth0MembershipWithMeta, Auth0OrganizationData } from '@gr/shared/models';
import React, { Dispatch, SetStateAction, useContext } from 'react';

export interface IMembershipContext {
  membership: Auth0MembershipWithMeta | undefined;
  setMembership: Dispatch<SetStateAction<Auth0MembershipWithMeta | undefined>>;

  organizations: Auth0OrganizationData[] | undefined;
  setOrganizations: Dispatch<SetStateAction<Auth0OrganizationData[] | undefined>>;

  isMembershipLoaded: boolean;
  setMembershipLoaded: Dispatch<SetStateAction<boolean>>;
}

const MembershipContext = React.createContext<IMembershipContext>({
  membership: undefined,
  setMembership: () => { },

  organizations: undefined,
  setOrganizations: () => { },

  isMembershipLoaded: false,
  setMembershipLoaded: () => { },
});

const MembershipContextProvider = MembershipContext.Provider;
const MembershipContextConsumer = MembershipContext.Consumer;
const useMembership = () => useContext(MembershipContext);
export { MembershipContext, MembershipContextConsumer, MembershipContextProvider, useMembership };

