import { useMembership } from '@gr/portal/contexts/MembershipContext';
import { Auth0OrganizationData } from '@gr/shared/models';
import { orderBy } from 'lodash';
import { Dropdown, IDropdownValue } from '../Dropdown';

interface ISubDomainDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const SubDomainDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
}: ISubDomainDropdownProps): JSX.Element => {
  const { organizations } = useMembership();

  const options = organizations
    ?.filter(org => !!org)
    ?.map(org => getOption(org))
    ?? [];

  const orderedOptions = orderBy(options, 'label');

  return (
    <Dropdown
      label="Organization"
      value={value}
      options={orderedOptions}
      onChange={onChange}
      onBlur={onBlur}
      error={errorMessage}
      disabled={disabled}
      full
      labelColor="black"
      className="px-3 pb-2"
    />
  );
};

function getOption(organization: Auth0OrganizationData): IDropdownValue {
  return {
    label: organization.display_name,
    value: organization.name,
  };
}

export default SubDomainDropdown;
