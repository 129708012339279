import { useAuth0UserData } from '@gr/portal/hooks/useAuth0Custom';
import { SQSQueueNameEnum } from '@gr/shared/enums';
import {
  IHttpResponse,
  IOptoutsCreateRequest,
  IOptoutsUploadRequest,
  ISQSSendRequest,
} from '@gr/shared/models';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import axios, { AxiosError } from 'axios';
import { Fragment, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { axiosPost } from '../../authAxios';
import { useNotifications } from '../../contexts/NotificationContext';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import UploadOptOutForm, { IOptOutUploadForm } from './UploadOptOutForm';
import { s3UrlRequest } from './types';

interface IUploadOptOutModalProps {
  show: boolean;
  setShow: any;
}

const UploadOptOutModal = ({ show, setShow }: IUploadOptOutModalProps) => {
  const { addNotification } = useNotifications();
  const [uploadingFile, setUploadingFile] = useState(false);
  const { user } = useAuth0UserData();
  const [phoneErrorMsg, setPhoneErrorMsg] = useState();

  const uploadOptOuts = async (formData: IOptOutUploadForm) => {
    try {
      setUploadingFile(true);

      if (formData.optOutList) {
        if (!user?.email) {
          throw new Error('User email needs to be set');
        }

        await uploadFile(formData, user.email, setShow, addNotification);
      } else {
        await saveSingleOptOut(formData, setShow, setPhoneErrorMsg);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setUploadingFile(false);
    }
  };

  function onClose() {
    setShow(false);
    setPhoneErrorMsg(undefined);
  }

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl dark:bg-slate-700 rounded-2xl">
              <Button
                className="absolute top-0 right-0 rounded-tr-2xl hover:bg-gray-100 dark:hover:bg-slate-800"
                variant={ButtonVariantEnum.TEXT_DEFAULT}
                leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
                onClick={onClose}
              />

              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                <span id="upload-optouts-modal-title">Upload Opt-out(s)</span>
              </Dialog.Title>
              <div className="mt-2">
                <UploadOptOutForm onSubmit={uploadOptOuts} loading={uploadingFile} phoneError={phoneErrorMsg} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

async function saveSingleOptOut(formData: IOptOutUploadForm, setShow: Function, setPhoneErrorMsg: Function) {
  await axiosPost<IHttpResponse<string>>('/optouts-create', {
    clientId: formData.client!.value,
    phone: formData.phone!.toString(),
  } as IOptoutsCreateRequest).catch((error: AxiosError) => {
    if (error.response?.status === 422) {
      setPhoneErrorMsg(`"${formData.phone}" already exists as optout`);
    }
    throw error;
  });

  setShow(false);
  setPhoneErrorMsg(null);
}

async function uploadFile(
  formData: IOptOutUploadForm,
  email: string,
  setShow: any,
  addNotification: Function
): Promise<void> {
  const fileKey = uuid();

  s3UrlRequest.key = fileKey;

  // Grab s3 signed url
  const {
    data: { data: uploadUrl },
  } = await axiosPost<IHttpResponse<string>>('/utility-get-signed-s3-url', s3UrlRequest);

  // Upload file using default axios to avoid auth token
  await axios.put(uploadUrl, formData.optOutList, {
    headers: { 'Content-Type': 'text/csv' },
  });

  const triggerPayload: ISQSSendRequest<IOptoutsUploadRequest> = {
    messageId: uuid(),
    queueName: SQSQueueNameEnum.OPTOUTS_UPLOAD,
    payload: {
      clientId: formData.client!.value,
      fileKey: fileKey,
      fileName: formData.optOutList.name,
      userEmail: email,
    },
  };

  // Kick off lambda to pull from csv
  await axiosPost<IHttpResponse<string>>('/utility-send-to-sqs', triggerPayload);

  setShow(false);

  addNotification({
    header: 'Opt-out list uploaded successfully!',
    content: `You will receive an email once the opt-outs have been added to the system.`,
  });
}

export default UploadOptOutModal;
