import { EntityName } from '@gr/shared/enums';
import { DropdownEntityType, FilterDataTypeEnum, FilterOperatorEnum, ISearchRequest, SearchSortDirectionEnum } from '@gr/shared/models';
import * as yup from 'yup';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { dropdownSchema } from '../shared/Schema/yup';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const defaultTestNumberTableOptions: ISearchRequest = {
  entity: EntityName.ALL_HEALTH_CHECK_NUMBERS_VIEW,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const defaultTestNumberDropdownOptions = (client: string): ISearchRequest => ({
  entity: EntityName.ALL_HEALTH_CHECK_NUMBERS_VIEW,
  filters: [{
    fieldName: 'clientId',
    value: [client, '11111111-1111-1111-1111-111111111111'],
    dataType: FilterDataTypeEnum.STRING,
    operator: FilterOperatorEnum.IN
  }],
  pagination: {
    skip: 0,
    take: 20,
  },
  sort: {
    fieldName: 'allClients',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
});

export const defaultCarrierSearchOptions: ISearchRequest = {
  entity: EntityName.CARRIERS,
  filters: [],
  pagination: {
    skip: 0,
    take: 100000,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Name',
      fieldName: 'name',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.STRING,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Number',
      fieldName: 'number',
      isRowHeader: false,
      fieldType: FilterDataTypeEnum.NUMBER,
      renderColumn: columnRenders['number'],
    },
    {
      headerName: 'Client',
      fieldName: 'clientName',
      isRowHeader: false,
      fieldType: FilterDataTypeEnum.TYPEAHEAD,
      dropdownType: DropdownEntityType.ALLCLIENT
    },
    {
      headerName: 'Carrier',
      fieldName: 'carrierName',
      isRowHeader: false,
      fieldType: FilterDataTypeEnum.STRING,
    },
  ];
};

export interface ITestNumberForm {
  name: string;
  number?: number;
  clients?: IDropdownValue[];
  allClients?: boolean;
}

export const testNumberFormSchema: yup.SchemaOf<ITestNumberForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  number: yup
    .number()
    .test('len', 'Number must be 10 digits long', (num) => num?.toString().length === 10)
    .defined('Required')
    .typeError('Required'), // TODO: Give up and use a string instead?
  clients: yup.array().of(dropdownSchema).when('allClients', {
    is: false,
    then: yup.array().of(dropdownSchema).min(1, 'Select at least one client or All Clients')
  }),
  allClients: yup.boolean()
});

export const healthCheckFilterDropdownOptions: IFilterDropdownOptions = {
  clientName: [],
};

