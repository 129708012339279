import { Auth0OrganizationAppID, Auth0OrganizationID, Auth0OrganizationName } from '@gr/shared/models';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { Auth0FrontendConfig } from '../providers/auth0-data.provider';

export interface Auth0MainConfig {
  clientId: Auth0OrganizationAppID;
  organization: Auth0OrganizationName;
  organizationId: Auth0OrganizationID;
}

export interface IAuth0ConfigContextContext {
  auth0Config: Auth0FrontendConfig | undefined;
  setAuth0Config: Dispatch<SetStateAction<Auth0FrontendConfig | undefined>>;
}

export const AUTH0_MAIN_CONFIG: Auth0MainConfig = {
  clientId: Auth0OrganizationAppID.P2P,
  organization: Auth0OrganizationName.P2P,
  organizationId: Auth0OrganizationID.P2P,
};

const Auth0ConfigContext = React.createContext<IAuth0ConfigContextContext>({
  auth0Config: undefined,
  setAuth0Config: () => { },
});

const Auth0ConfigContextProvider = Auth0ConfigContext.Provider;
const Auth0ConfigContextConsumer = Auth0ConfigContext.Consumer;
const useAuth0Context = () => useContext(Auth0ConfigContext);
export { Auth0ConfigContext, Auth0ConfigContextConsumer, Auth0ConfigContextProvider, useAuth0Context };

