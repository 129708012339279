import { IHttpResponse, ISearchRequest, ISearchResponse, ITestNumberDto } from '@gr/shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useTestNumbers(
  searchOptions: ISearchRequest | null
): UseAxiosResult<IHttpResponse<ISearchResponse<ITestNumberDto>>> {
  return useAxios<IHttpResponse<ISearchResponse<ITestNumberDto>>>({
    url: 'test-numbers-get',
    method: 'POST',
    data: searchOptions,
  });
}
